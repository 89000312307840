<template>
  <app-module-view>
    <template slot="body">
      <app-history-filter></app-history-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="history"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '../preloader/Preloader'
import HistoryFilter from './HistoryFilter'
import DataTable from '../shared/DataTable'
import HistoryTypeMixin from '../mixins/valueObject/eshop/HistoryTypeMixin'
import StoreService from '../../services/eshop/StoreService'

export default {
  name: 'HistoryList',
  mixins: [HistoryTypeMixin],
  data () {
    return {
      page: 1,
      dataTableConfig: {
        fields: {
          id: this.$t('eshopHistory.id'),
          type: this.$t('eshopHistory.type'),
          order: this.$t('eshopHistory.order'),
          payment: this.$t('eshopHistory.payment'),
          description: this.$t('eshopHistory.description'),
          createdBy: this.$t('eshopHistory.created_by'),
          feUserFull: this.$t('eshopHistory.feUser'),
          createdAt: this.$t('eshopHistory.created_at')
        },
        render: {
          type: function (data) {
            return this.historyTypeById(data).title
          }.bind(this)
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    history () {
      return this.$store.getters['eshopHistory/list']
    },
    totalCount () {
      return this.$store.getters['eshopHistory/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['eshopHistory/totalCount'] / config.defaults.list.limit)
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appHistoryFilter: HistoryFilter,
    appDataTable: DataTable
  },
  methods: {
    getHistory () {
      this.$store.dispatch('eshopHistory/fetch')
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('eshopHistory/setPage', page)
      this.getHistory()
    }
  },
  mounted () {
    this.page = this.$store.getters['eshopHistory/page']
    this.getHistory()
    StoreService.fetchAll()
  }
}
</script>

<style lang="scss">

</style>
